<template>
  <div class="promiseLetter">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      submitMethod="get"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :hasHeader="false"
      @update="update"
    >
      <el-form-item
        label="标题"
        :rules="[
          { required: true, message: '请输入投票标题', trigger: 'blur' },
        ]"
        prop="promiseTitle"
      >
        <v-input
          placeholder="请输入标题"
          :width="width"
          v-model="form.promiseTitle"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="承诺书内容"
        :rules="[
          { required: true, message: '请输入承诺书内容', trigger: 'blur' },
        ]"
        prop="promiseContent"
      >
        <v-ueditor :maxLength="20000" v-model="form.promiseContent" />
      </el-form-item>
      <template #saveBeforeSlot
        ><v-button text="保存" @click="save"> </v-button>
      </template>
    </form-panel>
  </div>
</template>

<script>
import { promiseLetterUrl, promiseDetailUrl } from "./api.js";
import {} from "./map.js";

export default {
  name: "promiseLetter",
  props: {},
  data() {
    return {
      width: 220,
      submitConfig: {
        queryUrl: promiseDetailUrl,
      },

      form: {
        id: "",
        promiseTitle: "",
        promiseContent: "",
      },
    };
  },
  computed: {},
  watch: {},
  async created() {
    this.$nextTick(() => {
      this.$refs.formPanel.getData({});
    });
  },
  methods: {
    async save() {
      let params = {
        id: this.form.id,
        promiseTitle: this.form.promiseTitle,
        promiseContent: this.form.promiseContent,
      };
      let res = await this.$axios.post(`${promiseLetterUrl}`, params);
      if (res.code === 200) {
        this.$message.success("操作成功");
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return false;
    },
  },
};
</script>
<style lang="less" scoped>
.promiseLetter {
  padding-top: 50px;
}
</style>
